import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import promoCodesService from "../../../services/promoCodes/promoCodes.service";

export const findPromoCodes = createAsyncThunk(
    "promoCodes/findPromoCodes",
    async (params, thunkAPI) => {
        try {
            return await promoCodesService.find(params);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getPromoCode = createAsyncThunk(
    "promoCodes/getPromoCode",
    async ({ id }, thunkAPI) => {
        try {
            return await promoCodesService.get(id);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updatePromoCode = createAsyncThunk(
    "promoCodes/updatePromoCode",
    async ({ id, data }, thunkAPI) => {
        try {
            return await promoCodesService.update(id, data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const createPromoCode = createAsyncThunk(
    "promoCodes/createCampaign",
    async (data, thunkAPI) => {
        try {
            return await promoCodesService.create(data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);
export const uploadPromoCode = createAsyncThunk(
    "promoCodes/uploadPromoCode",
    async ({ id, data }, thunkAPI) => {
        try {
            return await promoCodesService.upload(id, data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);
export const deletePromoCode = createAsyncThunk(
    "promoCodes/deletePromoCode",
    async (id, thunkAPI) => {
        try {
            return await promoCodesService.delete(id);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    queryState: {
        page: 1,
        limit: 10,
        promoCodeType: null,
        campaign: null
    },
    totalElements: 0,
    isLoading: false,
    error: null
};

export const promoCodesSlice = createSlice({
    name: "promoCodes",
    initialState,
    reducers: {
        updateQueryState: (state, action) => {
            state.queryState = action.payload;
        },
        emptyErrorState: (state) => {
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findPromoCodes.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(findPromoCodes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.list = action.payload.data;
                state.totalElements = action.payload.total_elements;
            })
            .addCase(findPromoCodes.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload.errorMessage;
            });
    },
});

export const promoCodesActions = promoCodesSlice.actions;

export const selectPromoCodeById = (state, id) => state.promoCodes.list.find((promoCode) => promoCode.id === id);

export default promoCodesSlice.reducer;
