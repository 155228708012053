import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Box} from "@mui/system";
import {Field, Form, Formik} from "formik";
import {Button, FormHelperText, Stack, TextField} from "@mui/material";
import {DatePicker} from "../../../components/common/forms/DatePicker";
import {Link} from "react-router-dom";
import {SubmitButton} from "../../../components/common/forms/SubmitButton";
import {MultipleSearcher} from "../../../components/common/forms/MultipleSearcher";
import campaignsService from "../../../services/campaigns/campaigns.service";
import {Helmet} from "react-helmet-async";
import {SkeletonForm} from "../../../components/common/forms/SkeletonForm";

export const OfferForm = ({ offer, isAddMode, handleSubmit, loading }) => {
    const { t } = useTranslation();
    const now = new Date();
    const initialValues = {
        name: "",
        description: "",
		description_ca: "",
        id_partner: "",
        campaign: [],
        start_date: new Date(`${now.getFullYear()}/${now.getMonth()+1}/${now.getDate()}`),
        end_date: new Date("2050/12/31")
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required(t('validation.required')),
        id_partner: Yup.string()
            .required(t('validation.required')),
        campaign: Yup.array()
            .min(1, t('validation.required')),
        start_date: Yup.date()
            .nullable()
            .transform((current, original) => original && !Number.isNaN(original) ? new Date(original) : null)
            .required(t('validation.required')),
        end_date: Yup.date()
            .min(Yup.ref('start_date'),  t('validation.date_end'))
            .nullable()
            .default(undefined)
            .transform((current, original) => original && !Number.isNaN(original) ? new Date(original) : null)
            .required(t('validation.required'))
    });

    return (
        <>
            { !loading && <h2>{isAddMode ? t('offers.offer.new.title') : t('offers.offer.edit.title', { offer })}</h2> }
            <Helmet>
                <title>{t('offers.title')} - Club Vanguardia</title>
            </Helmet>
            <Box sx={{
                display: "flex",
                justifyContent: "center" }}
            >
                <Box sx={{
                    display: "flex",
                    borderRadius: "4px",
                    maxWidth: { xs: "100%", md: "450px" },
                    width: "100%",
                    padding: "25px" }}
                >
                    { loading ? <SkeletonForm number={Object.keys(initialValues).length}></SkeletonForm> : (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={async (values) => handleSubmit(values)}
                            validationSchema={validationSchema}
                        >
                            {({
                                  handleChange,
                                  setFieldValue,
                                  isSubmitting,
                                  values,
                                  isValid,
                                  handleBlur,
                                  touched,
                                  errors
                              }) => {
                                useEffect(() => {
                                    if (!isAddMode) {
                                        Object.keys(initialValues)
                                            .forEach((key) => {
                                                setFieldValue(key, offer[key] || initialValues[key], false);
                                            });
                                    }
                                }, [offer]);

                                return (
                                    <Box
                                        component={Form}
                                        sx={{
                                            width: "100%",
                                            "& .MuiTextField-root": {
                                                marginTop: 1,
                                                marginBottom: 1,
                                                width: "100",
                                            },
                                            "& .MuiTextField-root:last-of-type": { marginBottom: 2 },
                                            "& .MuiAutocomplete-root .MuiTextField-root:first-of-type": { marginTop: 1 },
                                            "& .MuiAutocomplete-root .MuiTextField-root:last-of-type": { marginBottom: 1 }
                                        }}
                                        autoComplete="off"
                                    >
                                        <Field
                                            as={TextField}
                                            label={t('offers.offer.data.name')}
                                            id='name'
                                            type="text"
                                            name="name"
                                            onChange={handleChange("name")}
                                            onBlur={handleBlur("name")}
                                            value={values.name}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.name && Boolean(errors.name)}
                                        >
                                        </Field>
                                        {touched.name && errors.name && (
                                            <FormHelperText htmlFor='name' error>
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                        <Field
                                            as={TextField}
                                            label={t('offers.offer.data.id_partner')}
                                            id='id_partner'
                                            type="text"
                                            name="id_partner"
                                            onChange={handleChange("id_partner")}
                                            onBlur={handleBlur("id_partner")}
                                            value={values.id_partner}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.id_partner && Boolean(errors.id_partner)}
                                        >
                                        </Field>
                                        {touched.id_partner && errors.id_partner && (
                                            <FormHelperText htmlFor='id_partner' error>
                                                {errors.id_partner}
                                            </FormHelperText>
                                        )}
                                        <MultipleSearcher
                                            id="campaign"
                                            label={t('offers.offer.data.campaign')}
                                            getOptions={(value) => campaignsService.findAvailable({ limit:100, campaign: value })}
                                            getOptionLabel={(option) => (option.name) ? option.name + ' - ' + option.company: option}
                                            value={values.campaign}
                                            onValueChange={(value) => {
                                                setFieldValue("campaign", value ? value : [], false);
                                            }}
                                            once={false}
                                            error={touched.campaign && Boolean(errors.campaign)}
                                        >
                                        </MultipleSearcher>
                                        {touched.campaign && errors.campaign && (
                                            <FormHelperText htmlFor='campaign' error>
                                                {errors.campaign}
                                            </FormHelperText>
                                        )}
										<Field
                                            as={TextField}
                                            label={t('offers.offer.data.description')}
                                            id='description'
                                            type="text"
                                            name="description"
                                            onChange={handleChange("description")}
                                            onBlur={handleBlur("description")}
                                            value={values.description}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.description && Boolean(errors.description)}
                                        >
                                        </Field>
                                        {touched.description && errors.description && (
                                            <FormHelperText htmlFor='description' error>
                                                {errors.description}
                                            </FormHelperText>
                                        )}
                                        <Field
                                            as={TextField}
                                            label={t('offers.offer.data.description_ca')}
                                            id='description_ca'
                                            type="text"
                                            name="description_ca"
                                            onChange={handleChange("description_ca")}
                                            onBlur={handleBlur("description_ca")}
                                            value={values.description_ca}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.description_ca && Boolean(errors.description_ca)}
                                        >
                                        </Field>
                                        {touched.description_ca && errors.description_ca && (
                                            <FormHelperText htmlFor='description_ca' error>
                                                {errors.description_ca}
                                            </FormHelperText>
                                        )}
                                        <DatePicker
                                            label={t('offers.offer.data.start_date')}
                                            name="start_date"
                                            id='start_date'
                                            value={values.start_date}
                                            onBlur={handleBlur("start_date")}
                                            inputFormat="dd/MM/yyyy"
                                            disabled={!isAddMode}
                                            error={touched.start_date && Boolean(errors.start_date)}
                                        />
                                        {touched.start_date && errors.start_date && (
                                            <FormHelperText htmlFor='start_date' error>
                                                {errors.start_date}
                                            </FormHelperText>
                                        )}
                                        <DatePicker
                                            label={t('offers.offer.data.end_date')}
                                            name="end_date"
                                            id='end_date'
                                            value={values.end_date}
                                            onBlur={handleBlur("end_date")}
                                            inputFormat="dd/MM/yyyy"
                                            error={touched.end_date && Boolean(errors.end_date)}
                                        />
                                        {touched.end_date && errors.end_date && (
                                            <FormHelperText htmlFor='end_date' error>
                                                {errors.end_date}
                                            </FormHelperText>
                                        )}
                                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} style={{ justifyContent: "flex-end" }}>
                                            <Button component={Link} to="/offers" variant="outlined" color="secondary" disabled={isSubmitting}>
                                                {t("generic.cancel")}
                                            </Button>
                                            <SubmitButton
                                                text={t("generic.save")}
                                                isValid={isValid}
                                                isSubmitting={isSubmitting}
                                            ></SubmitButton>
                                        </Stack>
                                    </Box>
                                )
                            }}
                        </Formik>
                    )}
                </Box>
            </Box>
        </>
    );
};