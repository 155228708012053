import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { Suspense } from 'react';
import './i18n';
import {LinearProgress, ThemeProvider} from "@mui/material";
import themeConfig from "./common/config/theme.config";
import {ModalProvider} from "./common/providers/ModalProvider";
import { SnackbarProvider } from "notistack";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./common/store/store";
import {Helmet, HelmetProvider} from "react-helmet-async";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={themeConfig}>
                <ModalProvider>
                    <HelmetProvider>
                        <SnackbarProvider maxSnack={3} hideIconVariant>
                            <Suspense fallback={<LinearProgress color="secondary" />}>
                                <Helmet>
                                    <title>Club Vanguardia</title>
                                </Helmet>
                                <App />
                            </Suspense>
                        </SnackbarProvider>
                    </HelmetProvider>
                </ModalProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
