import React, { useState, createContext } from "react";

export const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {

    const [modal, setModal] = useState({
        title: '',
        content: null,
        confirmText: 'Aceptar',
        onSubmit: undefined,
        open: false
    });

    const contextValue = {
        modal,
        show: ({title, content, confirmText, onSubmit}) => {
            setModal({...modal, title, open: true, content, confirmText, onSubmit})
        },
        remove: () => {
            setModal({...modal, open: false, onSubmit: undefined})
        }
    };

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
        </ModalContext.Provider>
    );
};
