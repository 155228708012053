import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {deleteCampaign, findCampaigns, campaignsActions} from "../../../common/features/campaigns/campaignsSlicer";
import {CampaignsView} from "../presentational/CampaignsView";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

export const Campaigns = () => {
    const campaignsState = useSelector((state) => state.campaigns);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [queryState, setQueryState] = useState(campaignsState.queryState);

    const [rowsState, setRowsState] = useState({
        loading: true,
        rowCount: 0,
        pageSize: 10,
        rows: []
    });

    const handleRowsState = (state) => {
        setQueryState((prevState) => ({
            ...prevState,
            ...state
        }));
    };

    const handleDeletionRow = async (id) => {
        return dispatch(deleteCampaign(id))
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("generic.successful"), {
                    variant: "success",
                });
                return dispatch(findCampaigns(queryState))
            })
            .catch((e) => {
                enqueueSnackbar(t("generic.error"), {
                    variant: "error",
                });
            });
    };

    useEffect(() => {
        if (campaignsState.error) {
            enqueueSnackbar(campaignsState.error, {
                variant: "error",
            });
            dispatch(campaignsActions.emptyErrorState());
        }
        setRowsState((prev) => ({
            ...prev,
            loading: campaignsState.isLoading,
            rowCount: campaignsState.totalElements,
            rows: campaignsState.list,
        }));
    },[campaignsState]);

    useEffect(() => {
        dispatch(campaignsActions.updateQueryState(queryState));
        dispatch(findCampaigns(queryState));
    },[queryState]);

    return (
        <CampaignsView rowsState={rowsState} queryState={queryState} handleRowsState={handleRowsState} handleDeletionRow={handleDeletionRow}></CampaignsView>
    );
};