import React from "react";
import {Skeleton} from "@mui/material";
import {Box} from "@mui/system";

export const SkeletonForm = ({ number = 0, variant = "text", height = 40, width = "100%" }) => {
    return (
        <Box sx={{
            width: "100%" }}
        >
            { [...Array(number).keys()].map(i => <Skeleton key={i} variant={variant} height={height} width={width} />)}
        </Box>
    );
};