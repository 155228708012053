import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import promoCodesService from "../../../services/promoCodes/promoCodes.service";
import {PromoCodeForm} from "../../promoCodes/presentational/PromoCodeForm";
import {createPromoCode, updatePromoCode} from "../../../common/features/promoCodes/promoCodesSlicer";

export const PromoCode = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [promoCode, setPromoCode] = useState({});
    const [loading, setLoading] = useState(true);
    const isAddMode = id === "new";

    useEffect(() => {
        if (!isAddMode) {
            promoCodesService.get(id)
                .then((response) => {
                    setPromoCode(response.data[0]);
                    setLoading(false);
                })
                .catch(() => {
                    navigate("/promo-codes");
                    enqueueSnackbar(t("generic.not_found"), {
                        variant: "error",
                    });
                });
        } else {
            setLoading(false);
        }
    },[]);

    const handleSubmit = async ({ campaign, type, ...values } = {}) => {
        const data = Object.assign({}, {
            "promotype_id": type.id,
            "campaign_id": campaign.id,
            ...values
        });

        Object.keys(data).forEach(key => {
            if (Object.prototype.toString.call(data[key]) === '[object Date]') {
                data[key] = data[key].getTime();
            }
        });
        const promise = isAddMode ? dispatch(createPromoCode(data)) : dispatch(updatePromoCode({ id: id, data: data }));
        return promise
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("generic.successful"), {
                    variant: "success",
                });
                navigate("/promo-codes");
            })
            .catch((e) => {
                enqueueSnackbar(e.errorMessage || t("generic.error"), {
                    variant: "error",
                });
            });
    };

    return (
        <PromoCodeForm promoCode={promoCode} isAddMode={isAddMode} handleSubmit={handleSubmit} loading={loading}></PromoCodeForm>
    );
};