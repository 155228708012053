import {combineReducers, configureStore} from '@reduxjs/toolkit';
import campaignsReducer from "../features/campaigns/campaignsSlicer";
import offersReducer from "../features/offers/offersSlicer";
import promoCodesReducer from "../features/promoCodes/promoCodesSlicer";
import {persistReducer, persistStore} from "redux-persist";
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const defaultPersistConfig = (reducer) => {
  return {
    key: reducer,
    whitelist: ["queryState"],
    storage: new CookieStorage(Cookies, {
      expiration: {
        'default': 14400
      }
    }),
    stateReconciler: autoMergeLevel2
  };
};

const campaignsPersistConfig = defaultPersistConfig("campaigns");
const offersPersistConfig = defaultPersistConfig("offers");
const promoCodesPersistConfig = defaultPersistConfig("promoCodes");

const rootReducer = combineReducers({
  campaigns: persistReducer(campaignsPersistConfig, campaignsReducer),
  offers: persistReducer(offersPersistConfig, offersReducer),
  promoCodes: persistReducer(promoCodesPersistConfig, promoCodesReducer)
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
