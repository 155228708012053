import ajax from "../../helpers/ajax";
import utils from "../../helpers/utils";

const findPromoCodes = async ({ page = 1, limit = 10, promoCodeType = { id: ""}, campaign = { id: "" } } = {}) => {
    return ajax.get(
        utils.formatUrlWithParams(process.env.REACT_APP_CLUB_API_URL + "pm/promoCode", {
            limit: limit,
            page: page,
            promotype_id: promoCodeType && promoCodeType.id ? promoCodeType.id : "",
            campaign_id: campaign && campaign.id ? campaign.id : ""
        }),
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};

const getPromoCode = async (id) => {
    return ajax.get(
        process.env.REACT_APP_CLUB_API_URL + "pm/promoCode/" + id,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            type: "json"
        }
    );
};

const createPromoCode = async (data) => {
    return ajax.post(
        process.env.REACT_APP_CLUB_API_URL + "pm/promoCode",
        data,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            type: "json"
        }
    );
};

const updatePromoCode = async (id, data) => {
    return ajax.put(
        process.env.REACT_APP_CLUB_API_URL + "pm/promoCode/" + id,
        data,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            },
            type: "json"
        }
    );
};

const deletePromoCode = async (id) => {
    return ajax.delete(
        process.env.REACT_APP_CLUB_API_URL + "pm/promoCode/" + id,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};

const findPromoCodeTypes = async () => {
    return ajax.get(
        process.env.REACT_APP_CLUB_API_URL + "pm/promoCodeType",
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};
const uploadPromoCodes = (id, data) => {
    return ajax.post(
        process.env.REACT_APP_DATA_API_URL + "upload/promoCode/" + id,
        data,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "Content-Type": "multipart/form-data"
            },
            processData: false
        }        
    );
};

const promoCodesService = {
    find: findPromoCodes,
    get: getPromoCode,
    create: createPromoCode,
    update: updatePromoCode,
    delete: deletePromoCode,
    findTypes: findPromoCodeTypes,
    upload: uploadPromoCodes
};

export default promoCodesService;