import React, {useEffect, useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {useModal} from "../../../common/hooks/useModal/useModal";
import {DataGrid, esES, GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as Yup from "yup";
import {Box} from "@mui/system";
import {Form, Formik} from "formik";
import {Button, Stack} from "@mui/material";
import {SubmitButton} from "../../../components/common/forms/SubmitButton";
import utils from "../../../helpers/utils";
import campaignsService from "../../../services/campaigns/campaigns.service";
import {Searcher} from "../../../components/common/forms/Searcher";
import {SearcherGroup} from "../../../components/common/forms/SearcherGroup";
import promoCodesService from "../../../services/promoCodes/promoCodes.service";
import {Helmet} from "react-helmet-async";

export const PromoCodesView = ({ rowsState, queryState, handleRowsState, handleDeletionRow, handleUploadFile}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {rows, rowCount, pageSize} = rowsState;
    const [selectionModel, setSelectionModel] = useState([]);
    const modal = useModal();
    const inputLabelRef = useRef("");
    const inputRef = useRef(null);
    const buttonRef = useRef(null);
    const buttonInputLabelRef = useRef(null);
    
    const columns = [
        {
            headerName: t('promoCodes.promoCode.data.start_date'),
            title: "start_date",
            field: "start_date",
            valueFormatter: ({ value }) => utils.formatDate(value, "es"),
            flex: 1
        },
        {
            headerName: t('promoCodes.promoCode.data.end_date'),
            title: "end_date",
            field: "end_date",
            valueFormatter: ({ value }) => utils.formatDate(value, "es"),
            flex: 1
        },
        {
            headerName: t('promoCodes.promoCode.data.value'),
            title: "value",
            field: "value",
            valueGetter: (params) => {
                return params.value && Array.isArray(params.value) ? params.value.join(", ") : "";
            },
            flex: 4
        },
        {
            headerName: t('promoCodes.promoCode.data.total'),
            title: "total",
            field: "total",
            valueGetter: (params) => {
                return params.row.value && Array.isArray(params.row.value)? params.row.value.length : "";
            },
            flex: 1
        },
        {
            headerName: t('promoCodes.promoCode.data.frequency'),
            title: "frequency",
            field: "frequency",
            flex: 1
        },
        {
            headerName: t('promoCodes.promoCode.data.quantity'),
            title: "quantity",
            field: "quantity",
            flex: 1
        },
        {
            type: 'actions',
            headerName: t('actions.title'),
            title: "actions",
            field: "actions",
            flex: 2,
            getActions: ({id}) => {
                const actions = [];
                const promoCode = rows.find((promoCode) => promoCode.id === id);
                if (!promoCode)
                    return actions;
                
                actions.push(
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label={t('actions.edit')}
                        className="textPrimary"
                        onClick={() => navigate(`${id}`)}
                        color="inherit"/>);
                
                if (!promoCode.end_date || promoCode.end_date > new Date().getTime()) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label={t('actions.delete')}
                            onClick={() =>
                                modal.show({
                                    title: t('promoCodes.promoCode.delete.title'),
                                    content: t('promoCodes.promoCode.delete.content', {promoCode}),
                                    onSubmit: async () => handleDeletionRow(promoCode.id)
                                })
                            }
                            color="inherit"
                        />);
                } else {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label={t('actions.delete')}
                            disabled={true}
                            className="noAction"
                        />);
                }
                if (promoCode.promotype_id === 2 || promoCode.promotype_id === 5  || promoCode.promotype_id === 8 || promoCode.promotype_id === 10) {
                    actions.push(
                    <GridActionsCellItem
                        icon={<UploadFileIcon/>}
                        label={t('actions.upload_file')}
                        onClick={() => {
                            initUploadFile();
                            modal.show({
                                title: t('promoCodes.promoCode.upload_file.title'),
                                content:  (
                                    <Stack direction="row" alignItems="center" spacing={2} id="form_file" style={{backgroundColor: "#f5f5f5"}}>
                                        <Button variant="contained" component="label" id="custom-file-upload" htmlFor="file-upload" startIcon={<UploadFileIcon />} ref={buttonRef}>
                                            {t('promoCodes.promoCode.upload_file.button.upload')}
                                            <input hidden type="file" accept=".csv" id="file-upload" ref={inputRef} onChange={handleFileChange}/>
                                        </Button>
                                                                                
                                        <Button variant="outlined" component="label" color="secondary" id="button-file-upload" htmlFor="file_upload_label" disableRipple ref={buttonInputLabelRef} style={{display:"none"}}>
                                            <span id="file_upload_label"  name="file_upload_label" ref={inputLabelRef}></span>
                                        </Button>
                                        
                                    </Stack>
                                ),
                                confirmText: t('promoCodes.promoCode.upload_file.button.accept'),
                                onSubmit: async () => handleSubmitUploadFile(promoCode.id)
                            })
                        }}
                        color="inherit"
                    />);
                } else {
                    actions.push(
                        <GridActionsCellItem
                            icon={<UploadFileIcon />}
                            label={t('actions.upload_file')}
                            disabled={true}
                            className="noAction"
                        />);
                }
                return actions;
            },
        }
    ];
   
    function initUploadFile(){
        if (inputRef && inputRef.current != null){  
            inputRef.current = null;
        }
        if (inputLabelRef && inputLabelRef.current != null && inputLabelRef.current !== ""){
            inputLabelRef.current.textContent = '';
        }
        //buttonInputLabelRef.current.style.display="none";
    }
    function handleSubmitUploadFile(id) {
        if (id && (inputRef && inputRef.current != null)
            && (inputLabelRef && inputLabelRef.current && inputLabelRef.current.textContent !== '')){
            return handleUploadFile(id, inputRef.current);
        }
    }
    const handleFileChange = (e) => {  
        e.preventDefault();       
        const f = e.target.files[0];
        inputRef.current.setAttribute("disabled", true);
        buttonRef.current.style.display="none";
        inputRef.current = f;
        //inputLabelRef.current.textContent = t('promoCodes.promoCode.upload_file.content') + f.name;
        inputLabelRef.current.textContent = f.name;
        inputLabelRef.current.style.display="";
        buttonInputLabelRef.current.style.display="";
    };

    const initialValues = {
        promoCodeType: null,
        campaign: null
    };

    const validationSchema = Yup.object().shape({});

    const handleSubmit = (values) => {
        handleRowsState({...values, page: 1});
    };

    const handleReset = (resetForm) => {
        resetForm(initialValues);
        handleRowsState(initialValues);
    };

    const isQueryStatePopulated = () => Object.keys(initialValues).some(key => queryState[key]);

    return (
        <>
            <h2>{t('promoCodes.title')}</h2>
            <Helmet>
                <title>{t('promoCodes.title')} - Club Vanguardia</title>
            </Helmet>
            <Box sx={{
                display: "flex",
                borderRadius: "4px",
                maxWidth: {xs: "100%", md: "450px"},
                marginBottom: "30px",
                backgroundColor: "#f5f5f5",
                padding: "25px"
            }}
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => handleSubmit(values)}
                    validationSchema={validationSchema}
                >
                    {({
                          handleChange,
                          setFieldValue,
                          values,
                          isValid,
                          dirty,
                          resetForm,
                          handleBlur,
                          touched,
                          errors
                      }) => {
                        useEffect(() => {
                            Object.keys(initialValues).forEach(key => setFieldValue(key, queryState[key], false));
                        }, []);

                        return (
                            <Box
                                component={Form}
                                sx={{
                                    width: "100%",
                                    "& .MuiTextField-root": {
                                        marginTop: 1,
                                        marginBottom: 1,
                                        width: "100",
                                    },
                                    "& .MuiTextField-root:last-of-type": {marginBottom: 2},
                                    "& .MuiTextField-root:first-of-type": {marginTop: 0},
                                }}
                                autoComplete="off"
                            >
                                <SearcherGroup
                                    id="campaign"
                                    label={t('promoCodes.promoCode.data.company')+' - '+t('promoCodes.promoCode.data.campaign')}
                                    getOptions={(value) => campaignsService.findAvailable({ limit: 100, campaign: value })}
                                    getOptionLabel={(option) => (option.name) ? option.name: option}
                                    value={values.campaign}
                                    onValueChange={(value) => {
                                        setFieldValue("campaign", value ? value : null, false);
                                    }}
                                    once={false}
                                >
                                </SearcherGroup>
                                <Searcher
                                    id="type"
                                    label={t('promoCodes.promoCode.data.type')}
                                    getOptions={() => promoCodesService.findTypes()}
                                    getOptionLabel={(option) => option.name}
                                    value={values.promoCodeType}
                                    onValueChange={(value) => {
                                        setFieldValue("promoCodeType", value ? value : null, false);
                                    }}
                                    once={true}
                                >
                                </Searcher>
                                <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}
                                       style={{justifyContent: "flex-end"}}>
                                    {isQueryStatePopulated() && (
                                        <Button onClick={() => handleReset(resetForm)} variant="outlined"
                                                color="secondary">
                                            {t("generic.clean_filters")}
                                        </Button>
                                    )}
                                    <SubmitButton
                                        text={t("generic.search")}
                                        isValid={isValid && (dirty || isQueryStatePopulated())}
                                    ></SubmitButton>
                                </Stack>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
            <Stack spacing={2} direction={{xs: 'column', sm: 'row'}} style={{justifyContent: "flex-end"}}>
                <Button component={Link} to="new" variant="contained" color="secondary">
                    {t('promoCodes.promoCode.new.title')}
                </Button>
            </Stack>
            <div style={{width: "100%", marginTop: "10px"}}>
                <DataGrid
                    {...rowsState}
                    rows={rows}
                    columns={columns}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    autoHeight
                    pagination
                    paginationMode="server"
                    onPageChange={(page) => handleRowsState({page: page + 1})}
                    rowsPerPageOptions={[pageSize]}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                {t("generic.no_results")}
                            </Stack>
                        )
                    }}
                />
            </div>
            
        </>
    );
};
