import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useModal} from "../../../common/hooks/useModal/useModal";
import {DataGrid, esES, GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import * as Yup from "yup";
import {Box} from "@mui/system";
import {Field, Form, Formik} from "formik";
import {Button, FormHelperText, Stack, TextField} from "@mui/material";
import {SubmitButton} from "../../../components/common/forms/SubmitButton";
import {Helmet} from "react-helmet-async";

export const OffersView = ({ rowsState, queryState, handleRowsState, handleDeletionRow }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {rows, rowCount, pageSize} = rowsState;
    const [selectionModel, setSelectionModel] = useState([]);

    const modal = useModal();

    const columns = [
        {
            headerName: t('offers.offer.data.name'),
            title: "name",
            field: "name",
            flex: 3
        },
        {
            headerName: t('offers.offer.data.id_token'),
            title: "id_token",
            field: "id_token",
            flex: 3
        },
        {
            headerName: t('offers.offer.data.id_partner'),
            title: "id_partner",
            field: "id_partner",
            flex: 1
        },
        {
            type: 'actions',
            headerName: t('actions.title'),
            title: "actions",
            field: "actions",
            flex: 1,
            getActions: ({id}) => {
                const actions = [];
                const offer = rows.find((offer) => offer.id === id);
                if (!offer)
                    return actions;
                actions.push(
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label={t('actions.edit')}
                        className="textPrimary"
                        onClick={() => navigate(`${id}`)}
                        color="inherit"/>);
                if (!offer.end_date || offer.end_date > new Date().getTime()) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label={t('actions.delete')}
                            onClick={() =>
                                modal.show({
                                    title: t('offers.offer.delete.title'),
                                    content: t('offers.offer.delete.content', {offer}),
                                    onSubmit: async () => handleDeletionRow(offer.id)
                                })
                            }
                            color="inherit"
                        />);
                } else {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label={t('actions.delete')}
                            disabled={true}
                            className="noAction"                    
                        />);
                }
                return actions;
            },
        }
    ];

    const initialValues = {
        offer: "",
        tokenId: ""
    };

    const validationSchema = Yup.object().shape({
        offer: Yup.string()
            .min(2, t('validation.minimum_length', {minimumLength: 2})),
        tokenId: Yup.string()
            .min(2, t('validation.minimum_length', {minimumLength: 2}))
    });

    const handleSubmit = (values) => {
        handleRowsState({...values, page: 1});
    };

    const handleReset = (resetForm) => {
        resetForm(initialValues);
        handleRowsState(initialValues);
    };

    const isQueryStatePopulated = () => Object.keys(initialValues).some(key => queryState[key]);

    return (
        <>
            <h2>{t('offers.title')}</h2>
            <Helmet>
                <title>{t('offers.title')} - Club Vanguardia</title>
            </Helmet>
            <Box sx={{
                display: "flex",
                borderRadius: "4px",
                maxWidth: {xs: "100%", md: "450px"},
                marginBottom: "30px",
                backgroundColor: "#f5f5f5",
                padding: "25px"
            }}
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => handleSubmit(values)}
                    validationSchema={validationSchema}
                >
                    {({
                          handleChange,
                          setFieldValue,
                          values,
                          isValid,
                          dirty,
                          resetForm,
                          handleBlur,
                          touched,
                          errors
                      }) => {
                        useEffect(() => {
                            Object.keys(initialValues).forEach(key => setFieldValue(key, queryState[key], false));
                        }, []);

                        return (
                            <Box
                                component={Form}
                                sx={{
                                    width: "100%",
                                    "& .MuiTextField-root": {
                                        marginTop: 1,
                                        marginBottom: 1,
                                        width: "100",
                                    },
                                    "& .MuiTextField-root:last-of-type": {marginBottom: 2},
                                    "& .MuiTextField-root:first-of-type": {marginTop: 0},
                                }}
                                autoComplete="off"
                            >
                                <Field
                                    as={TextField}
                                    label={t('offers.offer.data.name')}
                                    id='offer'
                                    type="text"
                                    name="offer"
                                    onChange={handleChange("offer")}
                                    onBlur={handleBlur("offer")}
                                    value={values.offer}
                                    fullWidth
                                    autoComplete="off"
                                    size="small"
                                    error={touched.offer && Boolean(errors.offer)}
                                >
                                </Field>
                                {touched.offer && errors.offer && (
                                    <FormHelperText htmlFor='offer' error>
                                        {errors.offer}
                                    </FormHelperText>
                                )}
                                <Field
                                    as={TextField}
                                    label={t('offers.offer.data.id_token')}
                                    id='tokenId'
                                    type="text"
                                    name="tokenId"
                                    onChange={handleChange("tokenId")}
                                    onBlur={handleBlur("tokenId")}
                                    value={values.tokenId}
                                    fullWidth
                                    autoComplete="off"
                                    size="small"
                                    error={touched.tokenId && Boolean(errors.tokenId)}
                                >
                                </Field>
                                {touched.tokenId && errors.tokenId && (
                                    <FormHelperText htmlFor='tokenId' error>
                                        {errors.tokenId}
                                    </FormHelperText>
                                )}
                                <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}
                                       style={{justifyContent: "flex-end"}}>
                                    {isQueryStatePopulated() && (
                                        <Button onClick={() => handleReset(resetForm)} variant="outlined"
                                                color="secondary">
                                            {t("generic.clean_filters")}
                                        </Button>
                                    )}
                                    <SubmitButton
                                        text={t("generic.search")}
                                        isValid={isValid && (dirty || isQueryStatePopulated())}
                                    ></SubmitButton>
                                </Stack>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
            <Stack spacing={2} direction={{xs: 'column', sm: 'row'}} style={{justifyContent: "flex-end"}}>
                <Button component={Link} to="new" variant="contained" color="secondary">
                    {t('offers.offer.new.title')}
                </Button>
            </Stack>
            <div style={{width: "100%", marginTop: "10px"}}>
                <DataGrid
                    {...rowsState}
                    rows={rows}
                    columns={columns}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    autoHeight
                    pagination
                    paginationMode="server"
                    onPageChange={(page) => handleRowsState({page: page + 1})}
                    rowsPerPageOptions={[pageSize]}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                {t("generic.no_results")}
                            </Stack>
                        )
                    }}
                />
            </div>
            
        </>
    );
};