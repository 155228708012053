import React, {useEffect, useState} from "react";
import {CircularProgress, TextField, Autocomplete, Chip} from "@mui/material";
import {useDebounce} from "../../../common/hooks/useDebounce/useDebounce";
import {useTranslation} from "react-i18next";

export const MultipleSearcher = ({ id, label, getOptionLabel, getOptions, onValueChange, value, once, error = false }) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const debouncedValue = useDebounce(inputValue, 500);
    const [loading, setLoading] = useState(false);
    const [triggered, setTriggered] = useState(false);

    useEffect(() => {
        let active = true;
        if (!once || (once && !triggered)) {
            setOptions([]);
            if (!open) {
                return;
            }
            (async () => {
                if (active) {
                    setLoading(true);
                    getOptions(debouncedValue)
                        .then((response) => {
                            setTriggered(true);
                            setLoading(false);
                            setOptions([...response.data])
                        });
                }
            })();
        }

        return () => {
            active = false;
        };
    }, [open, debouncedValue]);

    useEffect(() => {
        onValueChange(selected);
    }, [selected]);

    return (
        <Autocomplete
            id={id}
            multiple
            sx={{ width: "100%" }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            clearIcon={null}
            value={value}
            onChange={(event, newValue) => {
                setSelected(newValue);
            }}
            loadingText={t('generic.loading')}
            noOptionsText={t('generic.no_results')}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => getOptionLabel(option) || ""}
            options={options}
            loading={loading}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={getOptionLabel(option) || ""} {...getTagProps({ index })} />
                ))
            }
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id} >
                        {getOptionLabel(option) || ""}
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    size="small"
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};