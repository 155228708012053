import ajax from "../../helpers/ajax";
import utils from "../../helpers/utils";

const findCampaigns = async ({ page = 1, limit = 10, campaign = "", category = "", company = "", startDate = "", endDate = "" } = {}) => {
    return ajax.get(
        utils.formatUrlWithParams(process.env.REACT_APP_CLUB_API_URL + "pm/campaign", {
            limit: limit,
            page: page,
            campaign: campaign,
            category:category,
            company: company,
            start_date: Object.prototype.toString.call(startDate) === '[object Date]' ? startDate.getTime() : "",
            end_date: Object.prototype.toString.call(endDate) === '[object Date]' ? endDate.getTime() : ""
        }),
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};

const findAvailableCampaigns = async ({ page = 1, limit = 10, campaign = "", category = "", company = "", startDate = "", endDate = "" } = {}) => {
    return ajax.get(
        utils.formatUrlWithParams(process.env.REACT_APP_CLUB_API_URL + "pm/campaign", {
            all: false,
            limit: limit,
            page: page,
            campaign: campaign,
            category:category,
            company: company,
            start_date: Object.prototype.toString.call(startDate) === '[object Date]' ? startDate.getTime() : "",
            end_date: Object.prototype.toString.call(endDate) === '[object Date]' ? endDate.getTime() : ""
        }),
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};

const getCampaign = async (id) => {
    return ajax.get(
        process.env.REACT_APP_CLUB_API_URL + "pm/campaign/" + id,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            type: "json"
        }
    );
};

const createCampaign = async (data) => {
    return ajax.post(
        process.env.REACT_APP_CLUB_API_URL + "pm/campaign",
        data,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            type: "json"
        }
    );
};

const updateCampaign = async (id, data) => {
    return ajax.put(
        process.env.REACT_APP_CLUB_API_URL + "pm/campaign/" + id,
        data,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            },
            type: "json"
        }
    );
};

const deleteCampaign = async (id) => {
    return ajax.delete(
        process.env.REACT_APP_CLUB_API_URL + "pm/campaign/" + id,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};

const findCategories = async () => {
    return {'data': [
        "CATEGORIA_COMPRAS",
        "CATEGORIA_EVENTO_COMPRAS", 
        "CATEGORIA_EVENTO_GASTRONOMIA",
        "CATEGORIA_EVENTO_MOTOR",
        "CATEGORIA_EVENTO_OCIO_Y_CULTURA",
        "CATEGORIA_EVENTO_SALUD_Y_BELLEZA",
        "CATEGORIA_EVENTO_SERVICIOS", 
        "CATEGORIA_EVENTO_VIAJES", 
        "CATEGORIA_GASTRONOMIA",
        "CATEGORIA_MOTOR",
        "CATEGORIA_NO CORRESPONDE",
        "CATEGORIA_OCIO_Y_CULTURA",
        "CATEGORIA_SALUD",
        "CATEGORIA_SALUD_Y_BELLEZA",
        "CATEGORIA_SERVICIOS",
        "CATEGORIA_SORTEO_COMPRAS",
        "CATEGORIA_SORTEO_GASTRONOMIA",
        "CATEGORIA_SORTEO_MOTOR",
        "CATEGORIA_SORTEO_OCIO_Y_CULTURA",
        "CATEGORIA_SORTEO_SALUD_Y_BELLEZA",
        "CATEGORIA_SORTEO_SERVICIOS",
        "CATEGORIA_SORTEO_VIAJES",
        "CATEGORIA_VIAJES"
    ]}
};

const campaignsService = {
    find: findCampaigns,
    get: getCampaign,
    create: createCampaign,
    update: updateCampaign,
    delete: deleteCampaign,
    findCategories: findCategories,
    findAvailable: findAvailableCampaigns
};

export default campaignsService;