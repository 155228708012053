import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Box} from "@mui/system";
import {Field, Form, Formik} from "formik";
import {Button, Chip, FormHelperText, MenuItem, Stack, TextField, Autocomplete, Checkbox} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import {DatePicker} from "../../../components/common/forms/DatePicker";
import {Link} from "react-router-dom";
import {SubmitButton} from "../../../components/common/forms/SubmitButton";
import {Searcher} from "../../../components/common/forms/Searcher";
import {SearcherGroup} from "../../../components/common/forms/SearcherGroup";
import campaignsService from "../../../services/campaigns/campaigns.service";
import promoCodesService from "../../../services/promoCodes/promoCodes.service";
import {Helmet} from "react-helmet-async";
import {SkeletonForm} from "../../../components/common/forms/SkeletonForm";

export const PromoCodeForm = ({ promoCode, isAddMode, handleSubmit, loading }) => {
    const { t } = useTranslation();
    const now = new Date();
    const initialValues = {
        type: null,
        campaign: null,
        value: [],
        frequency: 0,
        quantity: 0,
        url: "",
        online: 1,
        description: "",
		description_ca: "",
		description_btn: "",
		description_btn_ca: "",
        start_date: new Date(`${now.getFullYear()}/${now.getMonth()+1}/${now.getDate()}`),
        end_date: new Date("2050/12/31")
    };

    const frequency = [
        {
            id: 0,
            label: t('frequency.none')
        },
        {
            id: 30,
            label: t('frequency.monthly')
        }
    ];
    //const typeSingleIds = [1,3,4,6]
    const validationSchema = Yup.object().shape({
        
        type: Yup.object()
            .nullable()
            .required(t('validation.required')),
        campaign: Yup.object()
            .nullable()
            .required(t('validation.required')),
        value: Yup.array()
            .when("type",  {
                is: (row) => (row && row.type !== 2),
                then: Yup.array().min(1, t('validation.required_minimum')),
                otherwise: Yup.array().min(0, t('validation.required_minimum'))
            })
            .when("type",  {
                is: (row) => (row && row.type === 0),
                then: Yup.array().max(1, t('validation.required_maximun',{length: 1}))
            })
            .when("type",  {
                is: (row) => (row && row.type === 1),
                then: Yup.array().max(10, t('validation.required_maximun',{length: 10}))
            })
            .when("type",  {
                is: (row) => (row && row.type === 2),
                then: Yup.array().max(0, t('validation.required_maximun',{length: 0}))
            })
            /*.min(1, t('validation.required'))*/,
        frequency: Yup.string()
            .required(t('validation.required')),
        start_date: Yup.date()
            .nullable()
            .transform((current, original) => original && !Number.isNaN(original) ? new Date(original) : null)
            .required(t('validation.required')),
        end_date: Yup.date()
            .min(Yup.ref('start_date'),  t('validation.date_end'))
            .nullable()
            .default(undefined)
            .transform((current, original) => original && !Number.isNaN(original) ? new Date(original) : null)
            .required(t('validation.required'))
    });
    return (
        <>
            { !loading && <h2>{isAddMode ? t('promoCodes.promoCode.new.title') : t('promoCodes.promoCode.edit.title', { promoCode })}</h2> }
            <Helmet>
                <title>{t('promoCodes.title')} - Club Vanguardia</title>
            </Helmet>
            <Box sx={{
                display: "flex",
                justifyContent: "center" }}
            >
                <Box sx={{
                    display: "flex",
                    borderRadius: "4px",
                    maxWidth: { xs: "100%", md: "450px" },
                    width: "100%",
                    padding: "25px" }}
                >
                    { loading ? <SkeletonForm number={Object.keys(initialValues).length}></SkeletonForm> : (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={async (values) => handleSubmit(values)}
                            validationSchema={validationSchema}
                        >
                            {({
                                  handleChange,
                                  setFieldValue,
                                  isSubmitting,
                                  values,
                                  isValid,
                                  handleBlur,
                                  touched,
                                  errors
                              }) => {
                                useEffect(() => {
                                    if (!isAddMode) {
                                        Object.keys(initialValues)
                                            .forEach((key) => {
                                                var value = (key === 'online' && promoCode[key] === 0 )?promoCode[key] : promoCode[key] || initialValues[key];
                                                setFieldValue(key, value, false);
                                            });
                                    }
                                }, [promoCode]);

                                return (
                                    <Box
                                        component={Form}
                                        sx={{
                                            width: "100%",
                                            "& .MuiTextField-root": {
                                                marginTop: 1,
                                                marginBottom: 1,
                                                width: "100",
                                            },
                                            "& .MuiTextField-root:last-of-type": { marginBottom: 2 },
                                            "& .MuiAutocomplete-root .MuiTextField-root:first-of-type": { marginTop: 1 },
                                            "& .MuiAutocomplete-root .MuiTextField-root:last-of-type": { marginBottom: 1 }
                                        }}
                                        autoComplete="off"
                                    >
                                        <SearcherGroup
                                            id="campaign"
                                            label={t('promoCodes.promoCode.data.company')+' - '+t('promoCodes.promoCode.data.campaign')}
                                            getOptions={(value) => campaignsService.findAvailable({ limit: 100, campaign: value })}
                                            getOptionLabel={(option) => (option.name) ? option.name: option}
                                            value={values.campaign}
                                            onValueChange={(value) => {
                                                setFieldValue("campaign", value ? value : null, false);
                                            }}
                                            once={false}
                                            error={touched.campaign && Boolean(errors.campaign)}
                                        >
                                        </SearcherGroup>
                                        {touched.campaign && errors.campaign && (
                                            <FormHelperText htmlFor='campaign' error>
                                                {errors.campaign}
                                            </FormHelperText>
                                        )}
                                        <Searcher
                                            id="type"
                                            label={t('promoCodes.promoCode.data.type')}
                                            getOptions={() => promoCodesService.findTypes()}
                                            getOptionLabel={(option) => option.name}
                                            value={values.type}
                                            onValueChange={(value) => {
                                                setFieldValue("type", value ? value : null, false);
                                                //setFieldValue("value", [], true)
                                            }}
                                            once={true}
                                            error={touched.type && Boolean(errors.type)}
                                        >
                                        </Searcher>
                                        {touched.type && errors.type && (
                                            <FormHelperText htmlFor='type' error>
                                                {errors.type}
                                            </FormHelperText>
                                        )}
                                        <Autocomplete
                                            multiple
                                            id="value"
                                            sx={{ width: "100%" }}
                                            options={[]}
                                            value={values.value}
                                            clearIcon={null}
                                            freeSolo
                                            //autoSelect
                                            selectOnFocus
                                            clearOnBlur
                                            clearOnEscape                                            
                                            getOptionLabel={(option) => option}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            onChange={(event, newValue) => {
                                                setFieldValue("value", newValue, true);
                                                handleChange("value")
                                            }}
                                            onBlur={handleBlur("value")}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('promoCodes.promoCode.data.value')}
                                                    size="small"
                                                    error={touched.value && Boolean(errors.value)}
                                                />
                                            )}

                                        />
                                        {touched.value && errors.value && (
                                            <FormHelperText htmlFor='value' error>
                                                {errors.value}
                                            </FormHelperText>
                                        )}
                                        <Field
                                            as={TextField}
                                            label={t('promoCodes.promoCode.data.frequency')}
                                            id="frequency"
                                            select
                                            onChange={handleChange("frequency")}
                                            onBlur={handleBlur("frequency")}
                                            value={values.frequency}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.frequency && Boolean(errors.frequency)}
                                        >
                                            {frequency.map((item, i) => {
                                                return <MenuItem key={i} value={item.id}>{item.label}</MenuItem>;
                                            })}
                                        </Field>
                                        {touched.frequency && errors.frequency && (
                                            <FormHelperText htmlFor='frequency' error>
                                                {errors.frequency}
                                            </FormHelperText>
                                        )}
                                        <Field
                                            as={TextField}
                                            label={t('promoCodes.promoCode.data.quantity')}
                                            id='quantity'
                                            type="number"
                                            name="quantity"
                                            onChange={handleChange("quantity")}
                                            onBlur={handleBlur("quantity")}
                                            value={values.quantity}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.quantity && Boolean(errors.quantity)}
                                        >
                                        </Field>
                                        {touched.quantity && errors.quantity && (
                                            <FormHelperText htmlFor='quantity' error>
                                                {errors.quantity}
                                            </FormHelperText>
                                        )}
                                        <Field
                                            as={TextField}
                                            label={t('promoCodes.promoCode.data.description')}
                                            id='description'
                                            type="text"
                                            name="description"
                                            //onChange={handleChange("description")}
                                            //onBlur={handleBlur("description")}
                                            value={values.description}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.description && Boolean(errors.description)}
                                        >
                                        </Field>
                                        {touched.description && errors.description && (
                                            <FormHelperText htmlFor='description' error>
                                                {errors.description}
                                            </FormHelperText>
                                        )}
                                        <Field
                                            as={TextField}
                                            label={t('promoCodes.promoCode.data.description_ca')}
                                            id='description_ca'
                                            type="text"
                                            name="description_ca"
                                            //onChange={handleChange("description_ca")}
                                            //onBlur={handleBlur("description_ca")}
                                            value={values.description_ca}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.description_ca && Boolean(errors.description_ca)}
                                        >
                                        </Field>
                                        {touched.description_ca && errors.description_ca && (
                                            <FormHelperText htmlFor='description_ca' error>
                                                {errors.description_ca}
                                            </FormHelperText>
                                        )}
										<Field
                                            as={TextField}
                                            label={t('promoCodes.promoCode.data.description_btn')}
                                            id='description_btn'
                                            type="text"
                                            name="description_btn"
                                            //onChange={handleChange("description_btn")}
                                            //onBlur={handleBlur("description_btn")}
                                            value={values.description_btn}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.description_btn && Boolean(errors.description_btn)}
                                        >
                                        </Field>
                                        {touched.description_btn && errors.description_btn && (
                                            <FormHelperText htmlFor='description_btn' error>
                                                {errors.description_btn}
                                            </FormHelperText>
                                        )}
										<Field
                                            as={TextField}
                                            label={t('promoCodes.promoCode.data.description_btn_ca')}
                                            id='description_btn_ca'
                                            type="text"
                                            name="description_btn_ca"
                                            //onChange={handleChange("description_btn_ca")}
                                            //onBlur={handleBlur("description_btn_ca")}
                                            value={values.description_btn_ca}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.description_btn_ca && Boolean(errors.description_btn_ca)}
                                        >
                                        </Field>
                                        {touched.description_btn_ca && errors.description_btn_ca && (
                                            <FormHelperText htmlFor='description_btn_ca' error>
                                                {errors.description_btn_ca}
                                            </FormHelperText>
                                        )}
                                        <Field
                                            as={TextField}
                                            label={t('promoCodes.promoCode.data.url')}
                                            id='url'
                                            type="text"
                                            name="url"
                                            //onChange={handleChange("url")}
                                            //onBlur={handleBlur("url")}
                                            value={values.url}
                                            fullWidth
                                            autoComplete="off"
                                            size="small"
                                            error={touched.url && Boolean(errors.url)}
                                        >
                                        </Field>
                                        {touched.url && errors.url && (
                                            <FormHelperText htmlFor='url' error>
                                                {errors.url}
                                            </FormHelperText>
                                        )}
                                        
                                        <Field
                                            as={FormControlLabel}
                                            type="checkbox"
                                            id="online"
                                            name="online"
                                            value={values.online}
                                            control={<Checkbox 
                                                checked={values.online === 1}
                                                onChange={(value) => {
                                                    setFieldValue('online', value.target.checked?1:0)
                                                    }
                                                }
                                            />}
                                            label={t('promoCodes.promoCode.data.online')}
                                        />
                                        {touched.online && errors.online && (
                                            <FormHelperText htmlFor='online' error>
                                                {errors.online}
                                            </FormHelperText>
                                        )}
                                        
                                        <DatePicker
                                            label={t('promoCodes.promoCode.data.start_date')}
                                            name="start_date"
                                            id='start_date'
                                            value={values.start_date}
                                            onChange={handleChange("start_date")}
                                            onBlur={handleBlur("start_date")}
                                            inputFormat="dd/MM/yyyy"
                                            disabled={!isAddMode}
                                            error={touched.start_date && Boolean(errors.start_date)}
                                        />
                                        {touched.start_date && errors.start_date && (
                                            <FormHelperText htmlFor='start_date' error>
                                                {errors.start_date}
                                            </FormHelperText>
                                        )}
                                        <DatePicker
                                            label={t('promoCodes.promoCode.data.end_date')}
                                            name="end_date"
                                            id='end_date'
                                            value={values.end_date}
                                            onChange={handleChange("end_date")}
                                            onBlur={handleBlur("end_date")}
                                            inputFormat="dd/MM/yyyy"
                                            disabled={!isAddMode}
                                            error={touched.end_date && Boolean(errors.end_date)}
                                        />
                                        {touched.end_date && errors.end_date && (
                                            <FormHelperText htmlFor='end_date' error>
                                                {errors.end_date}
                                            </FormHelperText>
                                        )}
                                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} style={{ justifyContent: "flex-end" }}>
                                            <Button component={Link} to="/promo-codes" variant="outlined" color="secondary" disabled={isSubmitting}>
                                                {t("generic.cancel")}
                                            </Button>
                                            <SubmitButton
                                                text={t("generic.save")}
                                                isValid={isValid}
                                                isSubmitting={isSubmitting}
                                            ></SubmitButton>
                                        </Stack>
                                    </Box>
                                )
                            }}
                        </Formik>
                    )}
                </Box>
            </Box>
        </>
    );
};