import campaignsService from "../../../services/campaigns/campaigns.service";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const findCampaigns = createAsyncThunk(
    "campaigns/findCampaigns",
    async (params, thunkAPI) => {
        try {
            return await campaignsService.find(params);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getCampaign = createAsyncThunk(
    "campaigns/getCampaign",
    async ({ id }, thunkAPI) => {
        try {
            return await campaignsService.get(id);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateCampaign = createAsyncThunk(
    "campaigns/updateCampaign",
    async ({ id, data }, thunkAPI) => {
        try {
            return await campaignsService.update(id, data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const createCampaign = createAsyncThunk(
    "campaigns/createCampaign",
    async (data, thunkAPI) => {
        try {
            return await campaignsService.create(data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const deleteCampaign = createAsyncThunk(
    "campaigns/deleteCampaign",
    async (id, thunkAPI) => {
        try {
            return await campaignsService.delete(id);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    queryState: {
        page: 1,
        limit: 10,
        campaign: "",
        category: "",
        company: "",
        startDate: null,
        endDate: null
    },
    totalElements: 0,
    isLoading: false,
    error: null
};

export const campaignsSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {
        updateQueryState: (state, action) => {
            state.queryState = action.payload;
        },
        emptyErrorState: (state) => {
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findCampaigns.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(findCampaigns.fulfilled, (state, action) => {
                state.isLoading = false;
                state.list = action.payload.data;
                state.totalElements = action.payload.total_elements;
            })
            .addCase(findCampaigns.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload.message;
            });
    },
});

export const campaignsActions = campaignsSlice.actions;

export const selectCampaignById = (state, id) => state.campaigns.list.find((campaign) => campaign.id === id);

export default campaignsSlice.reducer;
