import {createTheme} from "@mui/material/styles";
import {caES, esES} from "@mui/material/locale";


const themeConfig = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#001c4c",
        },
        secondary: {
            main: "#baad72",
        },
        background: {
            default: "#fff",
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    color: "#fff",
                    backgroundColor: "#001c4c",
                },
            },
        }
    },
    esES,
    caES
});

export default themeConfig;