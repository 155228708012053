import React, {useState} from "react";
import {AppBar, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemText, Toolbar} from "@mui/material";
import {Link, matchPath, useNavigate} from "react-router-dom";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";

export const Nav = () => {
    const drawerWidth = 240;
    const [drawerToggle, setDrawerToggle] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const menu = [
        {
            text: t("campaigns.title"),
            url: "/campaigns"
        },
        {
            text: t("promoCodes.title"),
            url: "/promo-codes"
        },
        {
            text: t("offers.title"),
            url: "/offers"
        }
    ];

    const handleDrawerToggle = () => {
        setDrawerToggle(prev => !prev);
    };

    const CustomListItem = ({ to, text }) => {
        const onClick = (to) => {
            navigate(`${to}`);
        };
        const match = matchPath({
            path: to,
        }, window.location.pathname);
        return (
            <ListItem button onClick={() => onClick(to)} selected={match != null}>
                <ListItemText primary={text} />
            </ListItem>
        );
    };
    const domain = process.env.REACT_APP_ENV !== 'PRO' ? process.env.REACT_APP_ENV:"";
    const DrawerContent = () => {
        return (
            <div>
                <Toolbar />
                <Divider />
                <List onClick={handleDrawerToggle}>
                    {menu.map((item, i) => {
                        return (
                            <CustomListItem key={i} to={item.url} text={item.text} />
                        );
                    })}
                </List>
            </div>
        )
    };

    return (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to="/campaigns">
                        <div style={{
                            backgroundImage: "url(/images/logo.svg)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            minWidth: 200,
                            height: 56 }}>
                        </div>
                    </Link>
                    <div style={{ }}>
                        <span>{domain}</span>
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={drawerToggle}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                        disableScrollLock: true
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth
                        },
                    }}
                >
                   <DrawerContent></DrawerContent>
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth
                        },
                    }}
                    open
                >
                    <DrawerContent></DrawerContent>
                </Drawer>
            </Box>
        </>
    );
};