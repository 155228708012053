import React from "react";
import {Campaigns} from "../../views/campaigns/container/Campaigns";
import {Navigate, Route, Routes} from "react-router-dom";
import {Campaign} from "../../views/campaigns/container/Campaign";
import {Offers} from "../../views/offers/container/Offers";
import {Offer} from "../../views/offers/container/Offer";
import {PromoCodes} from "../../views/promoCodes/container/PromoCodes";
import {PromoCode} from "../../views/promoCodes/container/PromoCode";

export const Router = () => {
	return (
		<Routes>
			<Route path="campaigns" element={<Campaigns />} />
			<Route path="campaigns/:id" element={<Campaign />} />
			<Route path="offers" element={<Offers />} />
			<Route path="offers/:id" element={<Offer />} />
			<Route path="promo-codes" element={<PromoCodes />} />
			<Route path="promo-codes/:id" element={<PromoCode />} />
			<Route path="/" element={<Navigate to="campaigns" replace />} />
		</Routes>
	);
};