import * as React from "react";
import { Button, CircularProgress } from "@mui/material"

export const SubmitButton = ({ text, isSubmitting, isValid }) => {
    return (
        <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting || !isValid}
        >
            {isSubmitting && (
                <CircularProgress
                    size={24}
                    color="secondary"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            )}
            {text}
        </Button>
    )
};
