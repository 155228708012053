import React, {useContext, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {Button, CircularProgress} from "@mui/material";
import { ModalContext } from "../../../common/providers/ModalProvider";
import {useTranslation} from "react-i18next";

export const Modal = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();
    const modal = useContext(ModalContext);
    const [submitting, setSubmitting] = useState(false);

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={modal.modal.open}
                onClose={() => {
                    if (submitting)
                        return;
                    modal.remove();
                }}
                aria-labelledby="responsive-dialog-title"
                disableEscapeKeyDown
            >
                <DialogTitle id="responsive-dialog-title">
                    {modal.modal.title}
                </DialogTitle>
                <DialogContent>{modal.modal.content}</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={submitting}
                        onClick={async () => {
                            setSubmitting(true);
                            await modal.modal.onSubmit();
                            setSubmitting(false);
                            modal.remove();
                        }}
                        style={{ display: "flex", marginLeft: "auto" }}
                    >
                        {submitting && (
                            <CircularProgress
                                size={24}
                                color="secondary"
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                        {modal.modal.confirmText || t('modal.accept')}
                    </Button>
                    <Button variant="outlined" onClick={modal.remove} disabled={submitting}>
                        {t('modal.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

