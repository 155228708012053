import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {createCampaign, updateCampaign} from "../../../common/features/campaigns/campaignsSlicer";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {CampaignForm} from "../presentational/CampaignForm";
import campaignsService from "../../../services/campaigns/campaigns.service";

export const Campaign = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [campaign, setCampaign] = useState({});
    const [loading, setLoading] = useState(true);
    const isAddMode = id === "new";

    useEffect(() => {
        if (!isAddMode) {
            campaignsService.get(id)
                .then((response) => {
                    setCampaign(response.data[0]);
                    setLoading(false);
                })
                .catch(() => {
                    navigate("/campaigns");
                    enqueueSnackbar(t("generic.not_found"), {
                        variant: "error",
                    });
                });
        } else {
            setLoading(false);
        }
    },[]);

    const handleSubmit = async (values) => {
        const data = Object.assign({}, values);
        Object.keys(data).forEach(key => {
            if (Object.prototype.toString.call(data[key]) === '[object Date]') {
                data[key] = data[key].getTime();
            }
        });
        const promise = isAddMode ? dispatch(createCampaign(data)) : dispatch(updateCampaign({ id: id, data: data }));
        return promise
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("generic.successful"), {
                    variant: "success",
                });
                navigate("/campaigns");
            })
            .catch((e) => {
                enqueueSnackbar(e.errorMessage || t("generic.error"), {
                    variant: "error",
                });
            });
    };

    return (
        <CampaignForm campaign={campaign} isAddMode={isAddMode} handleSubmit={handleSubmit} loading={loading}></CampaignForm>
    );
};