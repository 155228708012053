import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {PromoCodesView} from "../presentational/PromoCodesView";
import {useSnackbar} from "notistack";
import {
    deletePromoCode,
    findPromoCodes,
    promoCodesActions,
    uploadPromoCode
} from "../../../common/features/promoCodes/promoCodesSlicer";

export const PromoCodes = () => {
    const promoCodesState = useSelector((state) => state.promoCodes);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [queryState, setQueryState] = useState(promoCodesState.queryState);

    const [rowsState, setRowsState] = useState({
        loading: true,
        rowCount: 0,
        pageSize: 10,
        rows: []
    });

    const handleRowsState = (state) => {
        setQueryState((prevState) => ({
            ...prevState,
            ...state
        }));
    };

    const handleDeletionRow = async (id) => {
        return dispatch(deletePromoCode(id))
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("generic.successful"), {
                    variant: "success",
                });
                return dispatch(findPromoCodes(queryState))
            })
            .catch((e) => {
                enqueueSnackbar(t("generic.error"), {
                    variant: "error",
                });
            });
    };

    const handleUploadFile = async (id, file) => {
        /*if (!id || !file || !file.current || !file.current.value){
            enqueueSnackbar(t("promoCodes.promoCode.upload_file.error_empty"), {
                variant: "error",
            });
            return;
        }  */      
        if (file.size > 5242880){
            enqueueSnackbar(t("promoCodes.promoCode.upload_file.error_maxsize"), {
                variant: "error",
            });
            return;
        }
        if (file.size === 0){
            enqueueSnackbar(t("promoCodes.promoCode.upload_file.error_empty"), {
                variant: "error",
            });
            return;
        }
        
        return dispatch(uploadPromoCode({ id: id, data: file }))
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("generic.successful"), {
                    variant: "success",
                });
                return dispatch(findPromoCodes(queryState))
            })
            .catch((e) => {
                enqueueSnackbar(t("generic.error"), {
                    variant: "error",
                });
            });
    };

    useEffect(() => {
        if (promoCodesState.error) {
            enqueueSnackbar(promoCodesState.error, {
                variant: "error",
            });
            dispatch(promoCodesActions.emptyErrorState());
        }
        setRowsState((prev) => ({
            ...prev,
            loading: promoCodesState.isLoading,
            rowCount: promoCodesState.totalElements,
            rows: promoCodesState.list,
        }));
    },[promoCodesState]);

    useEffect(() => {
        dispatch(promoCodesActions.updateQueryState(queryState));
        dispatch(findPromoCodes(queryState));
    },[queryState]);

    return (
        <PromoCodesView rowsState={rowsState} queryState={queryState} handleRowsState={handleRowsState} handleDeletionRow={handleDeletionRow} handleUploadFile={handleUploadFile}></PromoCodesView>
    );
};