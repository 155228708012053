const httpGet = (url, opts) => send('GET', url, null, {}, opts);

const httpPost = (url, data, opts) => sendPayload('POST', url, data, opts);

const httpPut = (url, data, opts) => sendPayload('PUT', url, data, opts);

const httpDelete = (url, opts) => send('DELETE', url, null, {}, opts);

const sendPayload = (method, url, data, opts) => {
    const { type, processData } = opts || {};

    let contentType =  type === 'json' ? 'application/json' : 'application/x-www-form-urlencoded';
    let payload = type === 'json' ? JSON.stringify(data) :  encodeFormPayload(data);
    if (processData === false) {
        contentType = null;
        payload = data;
    }
    return send(method, url, contentType, payload, opts);
};

const send = (method, url, contentType, data, opts) => {
    const { headers, responseType, credentials, ignoreParsing } = opts || {};
    return new Promise(function(resolve, reject) {
        opts = opts || {};
        let xhr = new XMLHttpRequest();

        xhr.open(method, url);

        if (headers)
            Object.keys(headers).forEach(function(key) {
                xhr.setRequestHeader(key, headers[key]);
            });

        if (contentType)
            xhr.setRequestHeader('Content-type', contentType);

        if (responseType)
            xhr.responseType = responseType;

        if (credentials === true)
            xhr.withCredentials = true;

        xhr.onload = function() {
            let succeded = xhr.status >= 200 && xhr.status < 300;
            let result = {};

            if (ignoreParsing) {
                result = xhr.response;
            } else {
                try {
                    result = JSON.parse(xhr.response);
                } catch (e) {}
            }

            if (succeded)
                resolve(result);
            else
                reject(result);
        };

        xhr.onerror = function() {
            reject(xhr.response);
        };

        xhr.send(data);
    });
};

const encodeFormPayload = (data) => {
    let payload = [];
    for (let key in data) {
        payload.push(key + "=" + encodeURIComponent(data[key]));
    };
    return payload.join("&");
};

const ajax = {
    get: httpGet,
    post: httpPost,
    put: httpPut,
    delete: httpDelete
};

export default ajax;