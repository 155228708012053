import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {deleteOffer, findOffers, offersActions} from "../../../common/features/offers/offersSlicer";
import {OffersView} from "../presentational/OffersView";

export const Offers = () => {
    const offersState = useSelector((state) => state.offers);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [queryState, setQueryState] = useState(offersState.queryState);

    const [rowsState, setRowsState] = useState({
        loading: true,
        rowCount: 0,
        pageSize: 10,
        rows: []
    });

    const handleRowsState = (state) => {
        setQueryState((prevState) => ({
            ...prevState,
            ...state
        }));
    };

    const handleDeletionRow = async (id) => {
        return dispatch(deleteOffer(id))
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("generic.successful"), {
                    variant: "success",
                });
                return dispatch(findOffers(queryState))
            })
            .catch((e) => {
                enqueueSnackbar(t("generic.error"), {
                    variant: "error",
                });
            });
    };

    useEffect(() => {
        if (offersState.error) {
            enqueueSnackbar(offersState.error, {
                variant: "error",
            });
            dispatch(offersActions.emptyErrorState());
        }
        setRowsState((prev) => ({
            ...prev,
            loading: offersState.isLoading,
            rowCount: offersState.totalElements,
            rows: offersState.list,
        }));
    },[offersState]);

    useEffect(() => {
        dispatch(offersActions.updateQueryState(queryState));
        dispatch(findOffers(queryState));
    },[queryState]);

    return (
        <OffersView rowsState={rowsState} queryState={queryState} handleRowsState={handleRowsState} handleDeletionRow={handleDeletionRow}></OffersView>
    );
};