import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { TextField } from "@mui/material";
import {ca, es} from "date-fns/locale";
import {useField, useFormikContext} from "formik";
import {useTranslation} from "react-i18next";

export const DatePicker = ({ error = false, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const { i18n } = useTranslation();

    const handleChange = (value) => {
        const newDate = value;
        // Check if date is present, user can leave it as an empty field
        if (newDate) {
            //Set's the given date to UTC midnight (start of the day)
            newDate.setHours(0,0,0,0);
        }
        setFieldValue(field.name, newDate);
    };

    return (
        <LocalizationProvider locale={i18n.language === "ca" ? ca : es} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField {...params} name={field.name} error={error} size="small" fullWidth />
                )}
            />
        </LocalizationProvider>
    );
}
