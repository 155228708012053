import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import offersService from "../../../services/offers/offers.service";
import {createOffer, updateOffer} from "../../../common/features/offers/offersSlicer";
import {OfferForm} from "../presentational/OfferForm";

export const Offer = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [offer, setOffer] = useState({});
    const [loading, setLoading] = useState(true);
    const isAddMode = id === "new";

    useEffect(() => {
        if (!isAddMode) {
            offersService.get(id)
                .then((response) => {
                    setOffer(response.data[0]);
                    setLoading(false);
                })
                .catch(() => {
                    navigate("/offers");
                    enqueueSnackbar(t("generic.not_found"), {
                        variant: "error",
                    });
                });
        } else {
            setLoading(false);
        }
    },[]);

    const handleSubmit = async ({ campaign, ...values } = {}) => {
        const data = Object.assign({}, {
            "campaigns": campaign && campaign.length > 0 ? campaign.map((value) => `${value.id}`) : [],
            ...values
        });
        Object.keys(data).forEach(key => {
            if (Object.prototype.toString.call(data[key]) === '[object Date]') {
                data[key] = data[key].getTime();
            }
        });
        const promise = isAddMode ? dispatch(createOffer(data)) : dispatch(updateOffer({ id: id, data: data }));
        return promise
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("generic.successful"), {
                    variant: "success",
                });
                navigate("/offers");
            })
            .catch((e) => {
                enqueueSnackbar(e.errorMessage || t("generic.error"), {
                    variant: "error",
                });
            });
    };

    return (
        <OfferForm offer={offer} isAddMode={isAddMode} handleSubmit={handleSubmit} loading={loading}></OfferForm>
    );
};