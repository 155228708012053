import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import offersService from "../../../services/offers/offers.service";

export const findOffers = createAsyncThunk(
    "offers/findOffers",
    async (params, thunkAPI) => {
        try {
            return await offersService.find(params);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getOffer = createAsyncThunk(
    "offers/getOffer",
    async ({ id }, thunkAPI) => {
        try {
            return await offersService.get(id);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const updateOffer = createAsyncThunk(
    "offers/updateOffer",
    async ({ id, data }, thunkAPI) => {
        try {
            return await offersService.update(id, data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const createOffer = createAsyncThunk(
    "offers/createOffer",
    async (data, thunkAPI) => {
        try {
            return await offersService.create(data);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const deleteOffer = createAsyncThunk(
    "offers/deleteOffer",
    async (id, thunkAPI) => {
        try {
            return await offersService.delete(id);
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

const initialState = {
    list: [],
    queryState: {
        page: 1,
        limit: 10,
        offer: "",
        tokenId: ""
    },
    totalElements: 0,
    isLoading: false,
    error: null
};

export const offersSlice = createSlice({
    name: "offers",
    initialState,
    reducers: {
        updateQueryState: (state, action) => {
            state.queryState = action.payload;
        },
        emptyErrorState: (state) => {
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findOffers.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(findOffers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.list = action.payload.data;
                state.totalElements = action.payload.total_elements;
            })
            .addCase(findOffers.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload.errorMessage;
            });
    },
});

export const offersActions = offersSlice.actions;

export const selectOfferById = (state, id) => state.offers.list.find((offer) => offer.id === id);

export default offersSlice.reducer;
