import ajax from "../../helpers/ajax";
import utils from "../../helpers/utils";

const findOffers = async ({ page = 1, limit = 10, offer = "", tokenId = "" } = {}) => {
    return ajax.get(
        utils.formatUrlWithParams(process.env.REACT_APP_CLUB_API_URL + "pm/offer", {
            limit: limit,
            page: page,
            name: offer,
            token_id: tokenId
        }),
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};

const getOffer = async (id) => {
    return ajax.get(
        process.env.REACT_APP_CLUB_API_URL + "pm/offer/" + id,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            type: "json"
        }
    );
};

const createOffer = async (data) => {
    return ajax.post(
        process.env.REACT_APP_CLUB_API_URL + "pm/offer",
        data,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
            type: "json"
        }
    );
};

const updateOffer = async (id, data) => {
    return ajax.put(
        process.env.REACT_APP_CLUB_API_URL + "pm/offer/" + id,
        data,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            },
            type: "json"
        }
    );
};

const deleteOffer = async (id) => {
    return ajax.delete(
        process.env.REACT_APP_CLUB_API_URL + "pm/offer/" + id,
        {
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        }
    );
};

const offersService = {
    find: findOffers,
    get: getOffer,
    create: createOffer,
    update: updateOffer,
    delete: deleteOffer
};

export default offersService;