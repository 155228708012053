const formatDate = (date, locale, options) => {
    return new Intl.DateTimeFormat(locale, options).format(date);
};

const isEmpty = (obj) => {
    if (!obj)
        return true;
    if (Array.isArray(obj))
        return !obj.length;
    if (typeof (obj) === 'object') {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
    }
    if (typeof (obj) === 'string')
        return (!obj && /^\s*$/.test(obj));
    return true;
};

const formatParams = (params) => {
    if (isEmpty(params))
        return "";
    return "?" + Object
        .keys(params)
        .map(function(key) {
            return key+"="+encodeURIComponent(params[key]);
        })
        .join("&");
};

const formatUrlWithParams = (url, params) => {
    params = params || {};
    if (Object.keys(params).length > 0)
        url = url + formatParams(params);
    return url;
};

const sleep = (ms) => new Promise(r => setTimeout(r, ms));

const utils = {
    formatDate,
    formatUrlWithParams,
    isEmpty,
    sleep
};

export default utils;