import React from 'react';
import './App.css';
import {Box} from "@mui/material";
import {Router} from "./common/router/Router";
import {Modal} from "./components/common/modal/Modal";
import {Nav} from "./components/common/nav/Nav";
import {Main} from "./components/common/main/Main";
import {BrowserRouter} from "react-router-dom";

const App = () => {
  return (
      <Box sx={{ display: "flex" }}>
          <BrowserRouter>
              <Nav/>
              <Main>
                  <Router />
              </Main>
          </BrowserRouter>
        <Modal/>
      </Box>
  );
}

export default App;
