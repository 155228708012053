import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataGrid, esES} from "@mui/x-data-grid";
import utils from "../../../helpers/utils";
import {Button, FormHelperText, Stack, TextField} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {useModal} from "../../../common/hooks/useModal/useModal";
import {Box} from "@mui/system";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {SubmitButton} from "../../../components/common/forms/SubmitButton";
import {DatePicker} from "../../../components/common/forms/DatePicker";
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Searcher} from "../../../components/common/forms/Searcher";
import campaignsService from "../../../services/campaigns/campaigns.service";

export const CampaignsView = ({ rowsState, queryState, handleRowsState, handleDeletionRow }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { rows, rowCount, pageSize } = rowsState;
    const [selectionModel, setSelectionModel] = useState([]);

    const modal = useModal();

    const columns = [
        {
            headerName: t('campaigns.campaign.data.start_date'),
            title: "start_date",
            field: "start_date",
            valueFormatter: ({ value }) => utils.formatDate(value, "es"),
            flex: 1
        },
        {
            headerName: t('campaigns.campaign.data.end_date'),
            title: "end_date",
            field: "end_date",
            valueFormatter: ({ value }) => utils.formatDate(value, "es"),
            flex: 1
        },
        {
            headerName: t('campaigns.campaign.data.company'),
            title: "company",
            field: "company",
            flex: 2
        },
        {
            headerName: t('campaigns.campaign.data.name'),
            title: "name",
            field: "name",
            flex: 2
        },
        {
            headerName: t('campaigns.campaign.data.category'),
            title: "category",
            field: "category",
            flex: 4
        },
        {
            type: 'actions',
            headerName: t('actions.title'),
            title: "actions",
            field: "actions",
            flex: 1,
            getActions: ({ id }) => {
                const actions = [];
                const campaign = rows.find((campaign) => campaign.id === id);
                if (!campaign)
                    return actions;
                actions.push(
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={t('actions.edit')}
                        className="textPrimary"
                        onClick={() => navigate(`${id}`)}
                        color="inherit" />);
                if (!campaign.end_date || campaign.end_date > new Date().getTime()) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label={t('actions.delete')}
                            onClick={() =>
                                modal.show({
                                    title: t('campaigns.campaign.delete.title'),
                                    content: t('campaigns.campaign.delete.content', { campaign }),
                                    onSubmit: async () => handleDeletionRow(campaign.id)
                                })
                            }
                            color="inherit"
                        />);
                } else {
                    actions.push(
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label={t('actions.delete')}
                            disabled={true}
                            className="noAction"                    
                        />);
                }
                return actions;
            },
        }
    ];

    const initialValues = {
        campaign: "",
        category: "",
        company: "",
        startDate: null,
        endDate: null
    };

    const validationSchema = Yup.object().shape({
        campaign: Yup.string()
            .min(2, t('validation.minimum_length', { minimumLength: 2 })),
        category: Yup.string()
            .min(2,  t('validation.minimum_length', { minimumLength: 2 })),
        company: Yup.string()
            .min(2,  t('validation.minimum_length', { minimumLength: 2 })),
        start_date: Yup.date().nullable(),
        end_date: Yup.date().nullable()
    });

    const handleSubmit = (values) => {
        handleRowsState({ ...values, page: 1 });
    };

    const handleReset = (resetForm) => {
        resetForm(initialValues);
        handleRowsState(initialValues);
    };

    const isQueryStatePopulated = () => Object.keys(initialValues).some(key => queryState[key]);

    return (
        <>
            <h2>{t('campaigns.title')}</h2>
            <Helmet>
                <title>{t('campaigns.title')} - Club Vanguardia</title>
            </Helmet>
            <Box sx={{
                display: "flex",
                borderRadius: "4px",
                maxWidth: { xs: "100%", md: "450px" },
                marginBottom: "30px",
                backgroundColor: "#f5f5f5",
                padding: "25px" }}
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => handleSubmit(values)}
                    validationSchema={validationSchema}
                >
                    {({
                          handleChange,
                          setFieldValue,
                          values,
                          isValid,
                          dirty,
                          resetForm,
                          handleBlur,
                          touched,
                          errors
                      }) => {
                        useEffect(() => {
                            Object.keys(initialValues).forEach(key => setFieldValue(key, queryState[key], false));
                        }, []);

                        return (
                            <Box
                                component={Form}
                                sx={{
                                    width: "100%",
                                    "& .MuiTextField-root": {
                                        marginTop: 1,
                                        marginBottom: 1,
                                        width: "100",
                                    },
                                    "& .MuiTextField-root:last-of-type": { marginBottom: 2 },
                                    "& .MuiTextField-root:first-of-type": { marginTop: 0 },
                                }}
                                autoComplete="off"
                            >
                                <Field
                                    as={TextField}
                                    label={t('campaigns.campaign.data.company')}
                                    id='company'
                                    type="text"
                                    name="company"
                                    onChange={handleChange("company")}
                                    onBlur={handleBlur("company")}
                                    value={values.company}
                                    fullWidth
                                    autoComplete="off"
                                    size="small"
                                    error={touched.company && Boolean(errors.company)}
                                >
                                </Field>
                                {touched.company && errors.company && (
                                    <FormHelperText htmlFor='company' error>
                                        {errors.company}
                                    </FormHelperText>
                                )}
                                <Field
                                    as={TextField}
                                    label={t('campaigns.campaign.data.name')}
                                    id='campaign'
                                    type="text"
                                    name="campaign"
                                    onChange={handleChange("campaign")}
                                    onBlur={handleBlur("campaign")}
                                    value={values.campaign}
                                    fullWidth
                                    autoComplete="off"
                                    size="small"
                                    error={touched.campaign && Boolean(errors.campaign)}
                                >
                                </Field>
                                {touched.campaign && errors.campaign && (
                                    <FormHelperText htmlFor='campaign' error>
                                        {errors.campaign}
                                    </FormHelperText>
                                )}
                                <Searcher
                                    id="category"
                                    label={t('campaigns.campaign.data.category')}
                                    getOptions={(value) => campaignsService.findCategories()}
                                    getOptionLabel={(option) => option}
                                    value={values.category}
                                    onValueChange={(value) => {
                                        setFieldValue("category", value ? value : null, false);
                                    }}
                                    once={true}
                                >
                                </Searcher>
                                <DatePicker
                                    label={t('campaigns.campaign.data.start_date')}
                                    name="startDate"
                                    id='startDate'
                                    value={values.startDate}
                                    onBlur={handleBlur("startDate")}
                                    maxDate={new Date()}
                                    minDate={null}
                                    inputFormat="dd/MM/yyyy"
                                    error={touched.startDate && Boolean(errors.startDate)}
                                />
                                {touched.startDate && errors.startDate && (
                                    <FormHelperText htmlFor='startDate' error>
                                        {errors.startDate}
                                    </FormHelperText>
                                )}
                                <DatePicker
                                    label={t('campaigns.campaign.data.end_date')}
                                    name="endDate"
                                    id='endDate'
                                    value={values.endDate}
                                    onBlur={handleBlur("endDate")}
                                    inputFormat="dd/MM/yyyy"
                                    error={touched.endDate && Boolean(errors.endDate)}
                                />
                                {touched.endDate && errors.endDate && (
                                    <FormHelperText htmlFor='endDate' error>
                                        {errors.endDate}
                                    </FormHelperText>
                                )}
                                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} style={{ justifyContent: "flex-end" }}>
                                    { isQueryStatePopulated() && (
                                        <Button onClick={() => handleReset(resetForm)} variant="outlined" color="secondary">
                                            {t("generic.clean_filters")}
                                        </Button>
                                    )}
                                    <SubmitButton
                                        text={t("generic.search")}
                                        isValid={isValid && (dirty || isQueryStatePopulated())}
                                    ></SubmitButton>
                                </Stack>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} style={{ justifyContent: "flex-end" }}>
                <Button component={Link} to="new" variant="contained" color="secondary">
                    {t('campaigns.campaign.new.title')}
                </Button>
            </Stack>
            <div style={{ width: "100%", marginTop: "10px" }}>
                <DataGrid
                    {...rowsState}
                    rows={rows}
                    columns={columns}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    autoHeight
                    pagination
                    paginationMode="server"
                    onPageChange={(page) => handleRowsState({ page: page + 1 })}
                    rowsPerPageOptions={[pageSize]}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                {t("generic.no_results")}
                            </Stack>
                        )
                    }}
                />
            </div>
            
        </>
    );
};